import React from 'react';
import './Navbar.css'; // Custom CSS file for styling
import { FaHome, FaUsers } from 'react-icons/fa'; // Importe les icônes ici
function Navbar() {
    return (
      <nav className="navbar">
        <div className="navbar-left">
          <h1 className="site-name">BENHAMIDECHE</h1>
        </div>
        <div className="navbar-right">
          <button className="nav-button">
            <FaHome /> Home
          </button>
          <button className="nav-button">
            <FaUsers /> Family
          </button>
          <button className="nav-button">
            About
          </button>
          <button className="nav-button">
            Contact
          </button>
        </div>
      </nav>
    );
  }
  export default Navbar;